<template>
  <div class="blog">
    <section class="section">
      <RenderlessPagesList :byTags="byTags" :byCategories="byCategories" :notTags="notTags" :notCategories="notCategories">
        <div slot-scope="{ posts, formatDate }">
          <div v-for="post in posts" :key="post.created" class="post">
            <router-link class="post__link" :to="`/blog/${ post.path }`" style="color:#2c3e50; text-decoration:none">
              <h2 class="post__title" style="font-size:1.5em;">{{ post.title }}</h2>
            </router-link>
            <h3 class="post__description" style="font-size:1.2em; color:#525a61; font-variant: small-caps">{{ post.description }}</h3>
            <div class="post-date text-center" style="font-size:1em;">{{ formatDate(post.created) | dateParse('MM/DD/YYYY') | dateFormat('D MMMM, YYYY') }}</div>
            <v-clamp autoresize :max-lines="6" class="post-content text-left" style="font-size:1.2em;">
              {{ post.__content | striphtml }}
            </v-clamp>
            <br>
            <div class="text-center" style="font-size:1.2em; color:#525a61;">
            Tags:
              <span v-for="(tag, index) in post.tags" v-bind:key="tag">
                <span v-if="index != 0">, </span>
                <span>{{tag}}</span>
              </span>
            </div>
            <br>
            <br>
          </div>
        </div>
      </RenderlessPagesList>
    </section>
  </div>
</template>

<script>
import RenderlessPagesList from "../components/RenderlessPagesList"
import blogPageListProps from "../components/blogPageListProps"
import VClamp from 'vue-clamp'

export default {
  props: blogPageListProps,
  components: {
    RenderlessPagesList,
    VClamp
  },
}
</script>

<style>

.container{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.post-content{
  hyphens: auto;
}
.v-clamp{
  hyphens: auto;
}

</style>
